<section id="beto-carrero" class="container card-body sombra-Card bg-white rounded main-content">
  <div class="row p-0">
    <div class="col-12 pl-0 ml-0">
      <h1 class="titulo" style="font-family: Roboto, 'Helvetica Neue', sans-serif;">Beto Carrero World</h1>
      <div class="imagem position-relative d-flex justify-content-center mb-4">
        <img class="d-none d-lg-block" src="assets/img/betoCarrero/betoCarrero.png" alt="Imagem de Abertura do Tema"
          style="border-radius: 10px;">
      </div>
    </div>
  </div>

  <div *ngIf="aba() == 'listagem'">
    <beto-carrero [events]="aba() == 'listagem' ? eventsSubject.asObservable() : null"
      [Orcamento]="Orcamento.BetoCarreroIngressos" [Busca]="Busca" (avancar)="rotaAvancar()"
      (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></beto-carrero>
  </div>

  <div *ngIf="aba() == 'pre-orcamento'">
    <beto-carrero-pre-orcamento [Busca]="Busca" [Orcamento]="Orcamento" (removeu)="atualizarOrcamento($event, false)"
      (avancou)="rotaAvancar()" (voltou)="rotaAnterior()"></beto-carrero-pre-orcamento>
  </div>

</section>