// tslint:disable: variable-name
// tslint:disable: ban-types

import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { Busca, Buscas, FiltroAereo, Orcamento } from "src/app/model";
import { ReservaService } from "src/app/services";
import { HomeComponent } from "../home/home.component";

export const BUSCA = "busca";
export const ORCAMENTO = "orcamentousuario";
@Component({
  selector: "app-listagem",
  templateUrl: "./listagem.component.html",
  styleUrls: ["./listagem.component.scss"],
})
export class ListagemComponent implements OnInit {
  Orcamento: Orcamento = new Orcamento();
  FiltroAereo: FiltroAereo = new FiltroAereo();
  eventsSubject: Subject<void> = new Subject<void>();
  Cias: String[] = [];
  reserva = [];
  rating = 5;
  progress: any;
  rangeInput: any;
  orcamentoAereo: any;
  badConditions: any;

  busca: Busca = new Busca();
  @Input() buscas: Buscas;
  @Input() opcoes: HomeComponent;

  constructor(
    public reservaService: ReservaService,
    public _router: Router,
    public route: ActivatedRoute
  ) { }

  aba() {
    if (this.route.snapshot.params.tipo != null) {
      return this.route.snapshot.params.tipo;
    } else {
      let x = this._router.url.split("/");

      return x[x.length - 1];
    }
    return "";
  }

  ngOnInit() {
    const id = this.route.snapshot.params.id;
    const busca = window.sessionStorage.getItem(BUSCA);

    if (busca !== null) {
      this.busca = this.busca.decodeBuscaNovo(busca);
      this.Orcamento.Id = this.busca.ReservaId;
    } else {
      if (id !== undefined) {
        this._router.navigate(["/" + id]);
      } else {
        this._router.navigate([""]);
      }
    }

    if (id !== undefined) {
      this.busca.primeira = false;
      this.busca.novoOrcamento = false;
      this.busca.orcamentoId = id;
      this.reservaService.buscaReservaBusca(id).subscribe(
        (dados) => {
          this.busca.quartos = dados.quartos;
          window.sessionStorage.setItem(BUSCA, JSON.stringify(this.busca));
        },
        (erros) => {
          console.log(erros);
        }
      );
    } else {
      const orcamento = window.sessionStorage.getItem(ORCAMENTO);
      if (orcamento != null) {
        this.Orcamento = JSON.parse(orcamento);
      }

      if (busca !== null) {
        this.busca = this.busca.decodeBuscaNovo(busca);
        this.Orcamento.Id = this.busca.ReservaId;
      } else {
        this._router.navigate([""]);
      }
      if (this._router.url === "/listas") {
        alert("verifica os passos da busca");
        this._router.navigate([""]);
      }

      const atualArr = this._router.url.split("/");
      const atual = atualArr[atualArr.length - 1];
    }
  }

  atualizarOrcamento(event, adicionou) {
    window.sessionStorage.setItem(ORCAMENTO, JSON.stringify(this.Orcamento));
    if (this.busca.primeira && adicionou) {
      window.sessionStorage.setItem(BUSCA, JSON.stringify(this.busca));

      const id = this.route.snapshot.params.id;

      if (this.aba() == "aereo" && id === undefined) {
        this.rotaAvancar();
      }
    }
  }

  rebuscou(busca) {
    this.eventsSubject.next(busca);
  }

  selecionarCia(cia) {
    setTimeout(() => {
      if (this.ciaSelecionada(cia)) {
        this.FiltroAereo.Cias = this.FiltroAereo.Cias.filter((x) => x !== cia);
      } else {
        this.FiltroAereo.Cias.push(cia);
      }

      this.refazerBusca(this.busca);
    }, 600);
  }

  ciaSelecionada(cia) {
    return this.FiltroAereo.Cias.indexOf(cia) >= 0;
  }

  rotaAvancar() {
    const tipo = this.aba();
    switch (tipo) {
      case "aereo":
        if (this.busca.tipoBusca.hotel) {
          return this.rota("hotel");
        }
        if (this.busca.tipoBusca.ticket) {
          return this.rota("servico");
        }
        if (this.busca.tipoBusca.carro) {
          return this.rota("carro");
        }
        return this.rota("revisa-orcamento");
      case "hotel":
        if (this.busca.tipoBusca.ticket) {
          return this.rota("servico");
        }
        if (this.busca.tipoBusca.carro) {
          return this.rota("carro");
        }
        return this.rota("revisa-orcamento");
      case "servico":
        if (this.busca.tipoBusca.carro) {
          return this.rota("carro");
        }
        return this.rota("revisa-orcamento");
      case "carro":
        return this.rota("revisa-orcamento");
    }
  }

  rotaAnterior() {
    const tipo = this.aba();
    switch (tipo) {
      case "revisa-orcamento":
        if (this.busca.tipoBusca.carro) {
          return this.rota("carro");
        }
        if (this.busca.tipoBusca.ticket) {
          return this.rota("servico");
        }
        if (this.busca.tipoBusca.hotel) {
          return this.rota("hotel");
        }
        return this.rota("aereo");
      case "carro":
        if (this.busca.tipoBusca.ticket) {
          return this.rota("servico");
        }
        if (this.busca.tipoBusca.hotel) {
          return this.rota("hotel");
        }
        return this.rota("aereo");
      case "servico":
        if (this.busca.tipoBusca.hotel) {
          return this.rota("hotel");
        }
        return this.rota("aereo");
      case "hotel":
        return this.rota("aereo");
    }
  }

  rota(texto) {
    if (this.busca.novoOrcamento) {
      this._router.navigate([`listas/${texto}`]);
    } else {
      this._router.navigate([`adicionar/${this.busca.orcamentoId}/${texto}`]);
    }
  }

  refazerBusca(busca) {
    this.busca = busca;
  }
}
