<div class="container w-100">
   <div class="resulList">

      <div *ngIf="buscando">
         <div class="row">
            <ng-container *ngFor="let integrador of buscasAtivas">
               <div class="col col-md-3 d-flex">
                  <p class="d-flex justify-content-center align-items-center">
                     {{ integrador }}  
                     <img style="width: 28px;" src="assets/img/loading1.gif" />
                  </p>
               </div>
            </ng-container>
         </div>
      </div>

      <div class='row mb-5 nav-options flex-row-reverse d-flex' [hidden]="buscando" *ngIf="Orcamento.length > 0">
         <div class='ml-auto col-4 btn-avancar'>
            <button [disabled]="buscando || (Orcamento.length == 0)" class="btn btn-success" (click)="rotaAvancar()">
               <a>Avançar <i class="fas fa-chevron-right"></i></a>
            </button>
         </div>
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="!buscando && mensagemErro.length > 0">
         {{"Nenhum carro encontrado" | translate}}
      </div>

      <div *ngFor="let carro of carros; let i = index">
         <app-carro [carro]="carro" [index]="i" (selecionouCarro)="selecionarCarro($event)"
            (removeuCarro)="removerCarro($event)"></app-carro>
      </div>
   </div>
   
</div>
<div class="justify-content-center col-md-12 col-lg-12 col-xxxl-12">
   <app-paginacao [paginacao]="paginacao" (alterarPagina)="buscarPagina($event)"></app-paginacao>
 </div>