<div class="listas">
  <div class="rebusca">
    <app-rebusca (rebuscou)="rebuscou($event)" [busca]="busca" [tipoBusca]="aba()"></app-rebusca>
  </div>
  <div [ngClass]="[aba() == 'revisa-orcamento' ? 'container revisao' : 'container']">
    <div class="rightDiv w-100">
      <div class="progress" style="height: 45px;">
        <div *ngIf="this.busca.tipoBusca.aereo" [ngClass]="{'progress-bar' : aba() == 'aereo' || Orcamento.Aereos.length > 0 , 'bg-secondary' : aba() != 'aereo' && Orcamento.Aereos.length == 0}" role="progressbar" (click)="rota('aereo')">
          <p class="pointer"><i class="fas fa-plane"></i> {{"Aéreo" | translate }}</p>
        </div>
        <div *ngIf="this.busca.tipoBusca.hotel" [ngClass]="{'progress-bar' : aba() == 'hotel' || Orcamento.Hoteis.length > 0 , 'bg-secondary' : aba() != 'hotel' && Orcamento.Hoteis.length == 0}" role="progressbar" (click)="rota('hotel')">
          <p class="pointer"><i class="fas fa-bed"></i> {{"Hotel" | translate }}</p>
        </div>
        <div *ngIf="this.busca.tipoBusca.ticket" [ngClass]="{'progress-bar' : aba() == 'servico' || Orcamento.Servicos.length > 0 , 'bg-secondary' : aba() != 'servico' && Orcamento.Servicos.length == 0}" role="progressbar" (click)="rota('servico')">
          <p class="pointer"><i class="fas fa-shuttle-van"></i>{{"Serviço" | translate }}</p>
        </div>
        <div *ngIf="this.busca.tipoBusca.carro" [ngClass]="{'progress-bar' : aba() == 'carro' || Orcamento.Carros.length > 0 , 'bg-secondary' : aba() != 'carro' && Orcamento.Carros.length == 0}" role="progressbar" (click)="rota('carro')">
          <p class="pointer"><i class="fas fa-car"></i> {{"Carro" | translate }}</p>
        </div>
        <div [ngClass]="{'progress-bar' : aba() == 'revisa-orcamento', 'bg-secondary' : aba() != 'revisa-orcamento'}" role="progressbar" (click)="rota('revisa-orcamento')" [class.bloqueiaclick]="busca.novoOrcamento && busca.tipoBusca.aereo && (Orcamento.Hoteis == null || Orcamento.Hoteis.length == 0)">
          <p class="pointer"><i class="fas fa-file-alt"></i> {{"Orçamento" | translate }}</p>
        </div>
      </div>
      <div class="results mt-4 {{aba() == 'carro' ? 'w-100' : ''}}">
        <div class="alinha" *ngIf="aba() == 'aereo'">
          <app-lista-aereo [events]="aba() == 'aereo' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.Aereos" (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></app-lista-aereo>
        </div>
        <div *ngIf="aba() == 'hotel'">
          <app-lista-hotel [events]="aba() == 'hotel' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.Hoteis" (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></app-lista-hotel>
        </div>
        <div *ngIf="aba() == 'servico'">
          <app-lista-servico [events]="aba() == 'servico' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.Servicos" (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></app-lista-servico>
        </div>
        <div *ngIf="aba() == 'carro'">
          <app-lista-carro [events]="aba() == 'carro' ? eventsSubject.asObservable() : null" (avancar)="rotaAvancar()" [busca]="busca" [Orcamento]="Orcamento.Carros" (removeu)="atualizarOrcamento($event, false)" (selecionou)="atualizarOrcamento($event, true)"></app-lista-carro>
        </div>
        <div *ngIf="aba() == 'revisa-orcamento'" class="revisao">
          <app-revisa-orcamento [busca]="busca" [Orcamento]="Orcamento"></app-revisa-orcamento>
        </div>
      </div>
    </div>
  </div>
</div>
