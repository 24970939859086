import { Injectable } from "@angular/core";
export class Aereo {
  public Id: number;
  public selecionado: boolean;
  public AereoOperacao: boolean;
  public Seguimentos: any;
  public Status: number;
  public VendaTotal: number;
  public Fornecedor: string;
  public SubFornecedor: string;
  public ExtraIntegracao: string;
  public ExtraIntegracao2: ExtraIntegracao;
  public PaxesTarifa: any;
  public TarifasTotal: any;
  public TaxasTotal: any;
  public LocalizadorAereo: any;
  public LimiteTempoEmissao: any;
  public Erros: any;
  public MoedaListagem: any;
  public TarifasListagemTotal : any;
  public TaxasListagemTotal : any;
  public TaxasDUListagemTotal : any;
  public ValorListagem : any;
  public TempoPorSeguimento: any[];
}

class ExtraIntegracao {
  public Disponivel: number;
  public Reservado: number;
  public Emitido: number;
}
export class Segmento {
  public AeroportoPartida: string;
  public AeroportoChegada: string;
  public DataPartida: Date;
  public PaisChegada: any;
}

@Injectable()
export class BuscaAereoRQ {
  // tslint:disable-next-line: no-use-before-declare
  public FiltroAereo: FiltroAereo = new FiltroAereo();
  public Adt: number;
  public Chd: number;
  public Inf: number;
  public Idades: string;
  public SegmentosBusca: Segmento[];
  public Guid: string;
  public Rebusca: boolean;
  public ReservaId: number;
  public AgenciaId: any;
  public AereoConsolidacao: boolean;
  public TipoBusca: string;
  public MaximoRegistros: number;
  public VooDireto: boolean;
}

export class FiltroAereo {
  public ValorMinimo: number;
  public ValorMaximo: number;
  public HorarioIdaMinimo: Number = 0;
  public HorarioIdaMaximo: Number = 24;
  public HorarioVoltaMinimo: Number = 0;
  public HorarioVoltaMaximo: Number = 24;
  public Cias: string[] = [];
  public Familia: string[] = [];
  public Voos: number[];
  public Bagagem: number = -1;
  public TodasFamilias: boolean;
  public Tarifa: string[];
  public Fornecedores: string[] = [];
  public MinimoDuracaoTrechos: number[] = [];
  public MaximoDuracaoTrechos: number[] = [];
  public NumeroParadasIda: Number = -1;
  public NumeroParadasVolta: Number = -1;
  public SemPernoite: boolean;
}
