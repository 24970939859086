<div *ngIf="showDetalhes" class="mt-5">
  <div class="d-md-block d-none">
    <div class="table-responsive">
      <table class="table" *ngIf="chave != undefined">
        <thead>
          <tr>
            <th colspan="4" style="text-align: left;" class="" scope="col">
              <p class="text-style text-center"><i class="fa fa-search"></i>{{"Registro de Alterações" | translate}}</p>
            </th>
          </tr>
          <tr>
            <th scope="col">{{"Criado por" | translate}}:</th>
            <th scope="col">{{"Criado em" | translate}}:</th>
            <th scope="col">{{"Ultima Alteração" | translate}}:</th>
            <th scope="col">{{"Alterado Por" | translate}}:</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{alteracoes.CriadoPor}}</th>
            <td>{{alteracoes.CriadoEm | date : 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>{{alteracoes.AlteradoEm | date : 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>{{alteracoes.AlteradoPor}}</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: right;"><a href="javascript:void(0)"
                (click)="open(content)">{{texto}}</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="d-block d-md-none">
    <table class="table" *ngIf="chave != undefined">
      <thead>
        <tr>
          <th colspan="4" style="text-align: center" scope="col">Registro de Alterações</th>
        </tr>
        <tr>
          <th scope="col">Ultima Alteração</th>
          <th scope="col">Alterado Por</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{alteracoes.AlteradoEm | date : 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{alteracoes.AlteradoPor}}</td>
        </tr>
        <tr>
          <td colspan="4" style="text-align: right"><a class="CorSecundaria" href="javascript:void(0)"
              (click)="open(content)">{{texto}}</a></td>
        </tr>
      </tbody>
    </table>

  </div>
</div>

<div *ngIf="!showDetalhes">
  <a href="javascript:void(0)" (click)="open(content)">{{texto}}</a>
</div>

<ng-template #content let-modal xlg>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Log de Alterações - {{entidade}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="col-12 col-md-4 mb-2 d-flex flex-column">
        <label for="inputCheckinDe">Data De:</label>
        <input type="date" [(ngModel)]="dataInicial" id="inputCheckinDe" name="inputCheckinDe" class="input-date" />
        <!-- <ngb-datepicker format="DD/MM/YYYY" #ngmodel></ngb-datepicker> -->
      </div>
      <div class="col-12 col-md-4 mb-2 d-flex flex-column">
        <label for="inputCheckinAte">Data Ate:</label>
        <input type="date" [(ngModel)]="dataFinal" id="inputCheckinAte" name="inputCheckinAte" class="input-date" />
      </div>
      <div class="col-12 col-md-3 mb-2 d-flex align-items-end">
        <button (click)="buscarPorParametro()" class="btn btn-success w-100" status="primary" size="small"
          title="Buscar" outline ghost>
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>

    <div class="d-md-block d-none">
      <div class="card-body table-full-width table-responsive row">
        <table class="table table-hover table-striped">
          <thead>
            <tr>
              <th>Data</th>
              <th>Ação</th>
              <th>Usuario</th>
              <th width="100%">Alteração</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dado of dados">
              <td class="text-align-vertical">{{dado.DataHora | date: 'dd/MM/yyyy'}}</td>
              <td class="text-align-vertical">{{dado.Acao}}</td>
              <td class="text-align-vertical">Id: {{dado.UsuarioId}} - {{dado.Usuario}}</td>
              <td>
                <textarea class="form-control " style="height: 100px !important;"
                  [readOnly]="true">{{dado.Alteracao}}</textarea>
              </td>
              <td>
                <a (click)="abrirModal(infoAlteracoes, dado)" class="font-mobile sublined menor"
                  style="cursor: pointer;">Visualizar</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-block d-md-none">
      <table class="table table-hover table-striped">
        <thead>
          <th>Data</th>
          <th>Ação</th>
          <th>Usuario</th>
        </thead>
        <tbody>
          <tr *ngFor="let dado of dados">
            <td>{{dado.DataHora | date: 'dd/MM/yyyy'}} <br /> {{dado.DataHora | date: 'HH:mm:ss'}}</td>
            <td>{{dado.Acao}}</td>
            <td>
              <a (click)="abrirModal(infoAlteracoes, dado)" class="font-mobile sublined menor">Visualizar</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template let-modal #infoAlteracoes let-c="close">
  <div class="modal-header">
    <div class="font-mobile bold roboto maior ml-3">
      Log
    </div>
    <button type="button" class="close close-mobile pt-2" data-dismiss="modal" aria-label="Close" (click)="c('Close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="w-100 mb-2 mt-2 text-center"><b>Alterador por</b> {{itemModal.UsuarioId}} -
      {{itemModal.Usuario}}
    </div>
    <textarea class="form-control textarea-autosize textaerea-mobile" style="height: 200px;"
      [readonly]="true">{{itemModal.Alteracao}}</textarea>
  </div>
</ng-template>
