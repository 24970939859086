import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BetoCarreroService } from '../../../services/turismo/beto-carrero.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
// import { NbDateService } from '@nebular/theme';
import * as moment from 'moment';

import { BetoCarrero, BuscaBetoCarreroRQ } from '../../../model/turismo/beto-carrero.model';
import { Busca, Orcamento, Usuario } from '../../../model';
import { LoginService } from '../../../services';

@Component({
  selector: 'beto-carrero',
  templateUrl: './beto-carrero.component.html',
  styleUrls: ['./beto-carrero.component.scss']
})
export class BetoCarreroComponent implements OnInit {

  // BuscaData: any = {};

  constructor(
    private loginService: LoginService,
    private betoCarreroService: BetoCarreroService,
    private modalService: NgbModal,
    // protected dateService: NbDateService<Date>,
  ) { }

  @Input() Orcamento: BetoCarrero[] = [];
  // @Input() Busca: Busca[];
  @Input() Busca: any = {};
  @Input() events: Observable<void>;

  @Output() selecionou = new EventEmitter<BetoCarrero>();
  @Output() removeu = new EventEmitter<BetoCarrero>();
  @Output() avancar = new EventEmitter<any>();

  Usuario: Usuario = new Usuario();
  passaporteDetalhes: BetoCarrero = new BetoCarrero();

  quantidadeIngressosSelecionados: { [CodItemIntegrador: number]: number } = {};
  temIngressosSelecionados: boolean;

  Lista: BetoCarrero[] = [];
  ListaPassaportes: BetoCarrero[] = [];
  ListaOpcionais: BetoCarrero[] = [];
  ListaCombos: BetoCarrero[] = [];
  ListaAlimentacao: BetoCarrero[] = [];

  // dataMinima = this.dateService.today();
  dataMinima = moment.now();

  mensagemSucesso: string = "";
  mensagemErro: string[] = [];
  carregando: boolean = false;
  semAgencia: boolean = false;
  adicionar: boolean = false;

  TipoIngressos = [
    { value: "Passaportes", active: false, selecionado: false },
    { value: "Opcionais", active: false, selecionado: false },
    { value: "Combos", active: false, selecionado: false },
    { value: "Alimentacao", active: false, selecionado: false }
  ]

  ngOnInit() {
    this.carregando = true;
    this.Usuario = this.loginService.user();

    // this.Busca.dataVisitaBetoCarrero = this.dateService.addDay(this.dateService.today(), 1);
    
    this.definirAgenciaId()

    setTimeout(() => {
      this.Buscar();
    }, 1000);

    this.setDataParaAmanha();
  }

  definirAgenciaId() {
    if (this.Usuario.AgenciaId != null) {
      this.Busca.AgenciaId = this.Usuario.AgenciaId; 
    } else {
      let agencia = window.localStorage.getItem("g8h7d8");
      if (agencia != null) {
        let a = JSON.parse(atob(agencia));
        this.Busca.AgenciaId = a.Id
      }
    }
  }

  setDataParaAmanha() {
    const hoje = new Date();
    const amanha = new Date(hoje.getTime() + (24 * 60 * 60 * 1000)); // Adiciona um dia (em milissegundos)
    this.Busca.dataVisitaBetoCarrero = this.formatarDataParaInputDate(amanha);
  }

  formatarDataParaInputDate(data: Date): string {
    return data.toISOString().split('T')[0];
  }

  Buscar() {
    this.mensagemErro = [];
    this.mensagemSucesso = "";
    this.Lista = [];
    this.ListaPassaportes = [];
    this.ListaOpcionais = [];
    this.ListaCombos = [];
    this.ListaAlimentacao = [];
    this.carregando = true;

    this.TipoIngressos.forEach(x => { x.active = false; x.selecionado = false;});

    this.definirAgenciaId();

    this.definirAgenciaId();

    if (this.Busca.primeira) {
      this.Orcamento = [];
      this.quantidadeIngressosSelecionados = {};
    }

    this.betoCarreroService.buscarIngressosBeto(this.Busca.getBuscaBetoCarrero())
     .subscribe(
      dados => {
        if (dados.Erros.length > 0 ) {
          this.mensagemErro = dados.Erros;
        } else {
          this.organizarIngressos(dados.IngressosBeto);
          this.TipoIngressos.find(x => x.value === "Passaportes").active = this.ListaPassaportes.length > 0;
          this.TipoIngressos.find(x => x.value === "Opcionais").active = this.ListaOpcionais.length > 0;
          this.mudarLista({ value: "Passaportes" });
        }
        this.carregando = false;
      },
      erro => {
        this.carregando = false;
        this.mensagemErro = this.errorHandler(erro);
      }
     );

    this.pegaIngressosSelecionados();

  }

  organizarIngressos(ingressos: any) {
    ingressos.forEach(ingresso => {
      switch (ingresso.CodigoTipoProduto) {
        case 110101:
          this.ListaPassaportes.push(ingresso);
          break;
      }
    });
  }

  mudarLista(valor) {
    this.Lista = this.obterListaPorValor(valor.value);

    this.TipoIngressos.forEach(x => {
      x.selecionado = x.value === valor.value;
    });
  }

  obterListaPorValor(valor) {
    const listaMapeamento = {
      Passaportes: this.ListaPassaportes,
      Opcionais: this.ListaOpcionais,
    };

    return listaMapeamento[valor] || [];
  }

  pegaIngressosSelecionados() {
    if (this.Orcamento.length > 0) {
      this.Orcamento.forEach(ingresso => {
        if (this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]) {
          this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]++;
        } else {
          this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] = 1;
        }
      });
    } else {
      this.quantidadeIngressosSelecionados = {};
    }

    this.verificaQuantidade();
  }

  selecionouIngresso(ingresso: BetoCarrero) {
    ingresso.selecionado = true;

    if (this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]) {
      this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]++;
    } else {
      this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] = 1;
    }
    this.selecionou.emit(ingresso);
    this.verificaQuantidade();
  }

  removeuIngresso(ingresso) {
    ingresso.selecionado = false;

    if (this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]) {
      this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador]--;

      if (this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador] === 0) {
        delete this.quantidadeIngressosSelecionados[ingresso.CodItemIntegrador];
      }
    }

    this.removeu.emit(ingresso);
    this.verificaQuantidade();
  }

  verificaQuantidade() {
    this.temIngressosSelecionados = Object.values(this.quantidadeIngressosSelecionados).length > 0;
  }

  continuar() {
    this.avancar.emit();
  }

  setDataPrevista(event) {
    this.Busca.dataVisitaBetoCarrero = event;

    this.Buscar();
  }

  dateMask(event, model) {
    let v = event.target.value;
    if (v.length == 10) {
      model = moment(v, "DD/MM/YYYY");
      if (model.isValid() && model.isBefore(moment())) {
        model = null;
        event.target.value = "";
      }
    }
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + "/";
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + "/";
    }
  }

  abrirModal(content, passaporte: BetoCarrero) {
    this.passaporteDetalhes = null;
    this.passaporteDetalhes = passaporte;

    if (this.passaporteDetalhes && this.passaporteDetalhes.EspecificacaoHtml) {
      const partes = this.passaporteDetalhes.EspecificacaoHtml.split('/n');

      this.passaporteDetalhes.EspecificacaoHtml = partes.join('<br/><br/>');   
    }
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true });
  }

  fecharModal() {
    this.modalService.dismissAll();
  }

  public errorHandler(erro): string[] {

    let mensagem = [];
    switch (erro.status) {
      case 0: mensagem.push('o servidor não respondeu, tente novamente mais tarde'); break;
      case 400:

      if (erro.error && erro.error.mensagem)
        mensagem.push(erro.error.mensagem);
      else
        mensagem.push("Houve algum erro na execução, tente novamente!");

      break;
    case 401: mensagem.push('Você não tem autorização para executar esta ação!"');
    case 404: mensagem.push(erro.error.mensagem); break;
    case 406: mensagem.push(erro.error.mensagem); break;
    case 500: mensagem.push(erro.error.Message); break;
    default: mensagem.push(erro.statusText);
    }
     if (erro.error != null && erro.error.mensagens != null) {
       mensagem.push(erro.error.mensagem);
    }
    return mensagem;

  }

  limparMsg() {
    this.mensagemErro = [];
    this.mensagemSucesso = "";
  }
}
